import React, { Component } from 'react'

import { Layout, Scramble, Typing } from '../components'

const ContactPage = () => (
  <Layout>
    <div>
      <h1><Typing>Get @ me 🤙</Typing></h1>
      <div>
        <strong>Email: </strong>
        <Scramble>sethkbonnie@gmail.com</Scramble>
      </div>
      <div>
        <strong>Twitter: </strong>
        <a href="http://twitter.com/sethbonnie">
          <Scramble>@sethbonnie</Scramble>
        </a>
      </div>
    </div>
  </Layout>
)

export default ContactPage
